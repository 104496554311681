@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

:root {
  --primary-color: #F4E041;
  --secondary-color: #00BDD3;
  --bg-color: #F8F8F8;
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 26px;
  background-color: var(--bg-color);
  overflow-x: hidden;
}

.container {
  width: 328px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    width: 704px;
  }

  @media (min-width: 1024px) {
    width: 904px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }
}

h1, h2, h3 {
  font-size: 40px;
  line-height: 40px;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.relative {
  position: relative;
}