.App {
  header {
    .container {
      height: 60px;
    }
  }
  .banner {
    &.container {
      width: 100%;
      @media (min-width: 1024px) {
        margin-right: auto;
        margin-left: auto;
        width: 904px;
      }
      @media (min-width: 1200px) {
        width: 1170px;
      }
    }
  }
}